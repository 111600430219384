import {
  Box,
  Grid,
  CardMedia,
  Divider,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
  Container,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import Layout from '../../components/layout';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import SEO from '../../components/seo';
import colors from '../../constants/colors';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import '../../styles/sass/global.scss';

const useStyles = makeStyles((theme) => ({
  socialIconHover: {
    color: colors.primary.dark,
    transition: 'color 0.2s ease-in-out',
    '&:hover': { color: 'white', cursor: 'pointer' },
  },
}));

const KnowledgeHubArticleInnerPage = ({ pageContext: { _knowledgeHub, pagination, matchPath } }) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Layout>
      <SEO
        title={_knowledgeHub.seoTitle ? _knowledgeHub.seoTitle : null}
        description={_knowledgeHub.seoDescription ? _knowledgeHub.seoDescription : null}
        keywords={_knowledgeHub.seoKeywords ? _knowledgeHub.seoKeywords : null}
        lang='en'
      />
      <SectionContainerLayoutWithFilter isViewAll baseLink='/knowledge-hub' title='KNOWLEDGE HUB'>
        {/* <Box
          width='100vw'
          left='50%'
          right='50%'
          ml='-50vw'
          mr='-50vw'
          position='relative'
          style={{ background: 'black' }}>
          <CardMedia
            component='img'
            src={`${process.env.GATSBY_CMS_API_URL}/static/${
              _knowledgeHub.file.find((_file) => _file.type !== 'application/pdf').code
            }`}
            height={400}
            style={{ opacity: 0.5 }}
          />
        </Box>
        <Box position='absolute' top={isMobile ? '25%' : '30%'} overflow='hidden'>
          <Grid container spacing={7}>
            <Grid item xs={12}>
              <Typography variant='h4' color='textPrimary'>
                <Box fontWeight='fontWeightBold' maxWidth='50ch'>
                  {_knowledgeHub.title}
                </Box>
              </Typography>
              <Box mt={1}>
                <Typography variant='h6' color='textPrimary'>
                  {moment(_knowledgeHub.date).format('DD MMMM')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' color='textPrimary' className='text-uppercase'>
                Share Now
              </Typography>
              <Box display='flex'>
                <Box pr={isMobile ? 1 : 4}>
                  <a
                    alt='Facebook'
                    href={`https://facebook.com/sharer/sharer.php?u=${encodeURI(
                      process.env.GATSBY_HOME_URL + matchPath.replace('/', '')
                    )}`}
                    target='_blank'
                    rel='noreferrer'>
                    <FacebookIcon className={classes.socialIconHover} />
                  </a>
                </Box>
                <Box pr={isMobile ? 1 : 4}>
                  <a
                    alt='Twitter'
                    href={`https://twitter.com/intent/tweet?url=${encodeURI(
                      process.env.GATSBY_HOME_URL + matchPath.replace('/', '')
                    )}`}
                    target='_blank'
                    rel='noreferrer'>
                    <TwitterIcon className={classes.socialIconHover} />
                  </a>
                </Box>
                <Box pr={isMobile ? 1 : 4}>
                  <a
                    alt='Linkedin'
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
                      process.env.GATSBY_HOME_URL + matchPath.replace('/', '')
                    )}`}
                    target='_blank'
                    rel='noreferrer'>
                    <LinkedInIcon className={classes.socialIconHover} />
                  </a>
                </Box>
                <Box pr={isMobile ? 1 : 4}>
                  <a
                    alt='WhatsApp'
                    href={`whatsapp://send?text=${encodeURI(process.env.GATSBY_HOME_URL + matchPath.replace('/', ''))}`}
                    target='_blank'
                    rel='noreferrer'>
                    <WhatsAppIcon className={classes.socialIconHover} />
                  </a>
                </Box>
                <Box pr={isMobile ? 1 : 4}>
                  <a
                    alt='Email'
                    href={`mailto:?body=${encodeURI(process.env.GATSBY_HOME_URL + matchPath.replace('/', ''))}`}
                    target='_blank'
                    rel='noreferrer'>
                    <EmailIcon className={classes.socialIconHover} />
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box> */}
        <Box
          width='100vw'
          left='50%'
          right='50%'
          ml='-50vw'
          mr='-50vw'
          height={'400px'}
          position='relative'
          style={{
            backgroundImage: `url(${process.env.GATSBY_CMS_API_URL}/static/${
              _knowledgeHub.file.find((_file) => _file.type !== 'application/pdf').code
            })`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            objectFit: 'cover',
            objectPosition: '50% 50%',
          }}>
          <Box
            position='absolute'
            top={0}
            left={0}
            width={1}
            height={1}
            style={{ backgroundColor: 'rgba(0,0,0, 0.3)' }}>
            <Container maxWidth='xl'>
              <Box py={5} top={'10%'} position='relative'>
                <Grid container spacing={7}>
                  <Grid item xs={12}>
                    <Typography variant='h4' color='textPrimary'>
                      <Box fontWeight='fontWeightBold' maxWidth='50ch'>
                        {_knowledgeHub.title}
                      </Box>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant='h6' color='textPrimary'>
                        {moment(_knowledgeHub.date).format('DD MMMM')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' color='textPrimary' className='text-uppercase'>
                      Share Now
                    </Typography>
                    <Box display='flex'>
                      <Box pr={isMobile ? 1 : 4}>
                        <a
                          alt='Facebook'
                          href={`https://facebook.com/sharer/sharer.php?u=${encodeURI(
                            process.env.GATSBY_HOME_URL + matchPath.replace('/', '')
                          )}`}
                          target='_blank'
                          rel='noreferrer'>
                          <FacebookIcon className={classes.socialIconHover} />
                        </a>
                      </Box>
                      <Box pr={isMobile ? 1 : 4}>
                        <a
                          alt='Twitter'
                          href={`https://twitter.com/intent/tweet?url=${encodeURI(
                            process.env.GATSBY_HOME_URL + matchPath.replace('/', '')
                          )}`}
                          target='_blank'
                          rel='noreferrer'>
                          <TwitterIcon className={classes.socialIconHover} />
                        </a>
                      </Box>
                      <Box pr={isMobile ? 1 : 4}>
                        <a
                          alt='Linkedin'
                          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
                            process.env.GATSBY_HOME_URL + matchPath.replace('/', '')
                          )}`}
                          target='_blank'
                          rel='noreferrer'>
                          <LinkedInIcon className={classes.socialIconHover} />
                        </a>
                      </Box>
                      {isMobile ? (
                        <Box pr={isMobile ? 1 : 4}>
                          <a
                            alt='WhatsApp'
                            href={`whatsapp://send?text=${encodeURI(
                              process.env.GATSBY_HOME_URL + matchPath.replace('/', '')
                            )}`}
                            target='_blank'
                            rel='noreferrer'>
                            <WhatsAppIcon className={classes.socialIconHover} />
                          </a>
                        </Box>
                      ) : null}
                      <Box pr={isMobile ? 1 : 4}>
                        <a
                          alt='Email'
                          href={`mailto:?body=${encodeURI(process.env.GATSBY_HOME_URL + matchPath.replace('/', ''))}`}
                          target='_blank'
                          rel='noreferrer'>
                          <EmailIcon className={classes.socialIconHover} />
                        </a>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Box>

        <Box mt={5}>
          <Grid container spacing={7}>
            <Grid item xs={12} md={8}>
              <Box fontSize='body1.fontSize' style={{ color: colors.text.secondary.dark }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: _knowledgeHub.description + '<br /><br />' + _knowledgeHub.article,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} className='no-print'>
              <Grid container xs={12}>
                <Box clone pb={2}>
                  <Grid item xs={12}>
                    <Typography variant='body1' color='textPrimary' className='text-uppercase'>
                      Most Recent Articles
                    </Typography>
                  </Grid>
                </Box>
                {pagination && pagination.length > 0
                  ? pagination.map((_kh, index) => (
                      <Grid item xs={12} key={index}>
                        <CardImageTitleDescription
                          isImgTag
                          image={_kh.file.find((_file) => _file.type !== 'application/pdf').code}
                          title={_kh.title}
                          body={_kh.description}
                          readMore={`/knowledge-hub/${_kh.year}/${_kh.urlSlug}`}
                          lines={3}
                          type={_kh.type === 'pdf' ? 'Report' : _kh.type}
                        />
                        {index + 1 < pagination.length ? (
                          <Box my={2}>
                            <Divider light />
                          </Box>
                        ) : null}
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default KnowledgeHubArticleInnerPage;
